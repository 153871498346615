<template>
  <section class="menu">
    <div class="menu-wrap">
      <div class="main-menu__header">
        <div class="main-menu__caption">Панель управления</div>
      </div>
      <div class="main-menu__button d-md-none" @click="menu_visible = true">
        <div class="main-menu__button-text">Выбрать раздел</div>
        <div class="main-menu__button-arrow">
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 11L6.75 6L1.75 1"
              stroke="#56565A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
      </div>
      <div class="main-menu" :class="{'main-menu_visible': menu_visible}">
        <div class="main-menu__close d-md-none" @click="menu_visible = false">
          <div class="main-menu__close-text">Закрыть</div>
          <div class="main-menu__close-icon">
            <svg
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.24508 6.5L13.2284 1.51571C13.4588 1.29521 13.5519 0.967575 13.4717 0.659124C13.3915 0.350673 13.1506 0.109578 12.8419 0.0287828C12.5331 -0.0520119 12.2047 0.0401128 11.9833 0.269642L7 5.25393L2.01668 0.269642C1.67012 -0.0619967 1.1215 -0.0558617 0.782462 0.283444C0.443426 0.622749 0.4386 1.17051 0.771603 1.51571L5.75392 6.5L0.771603 11.4843C0.541174 11.7048 0.448112 12.0324 0.528295 12.3409C0.608477 12.6493 0.849381 12.8904 1.15813 12.9712C1.46689 13.052 1.79527 12.9599 2.01668 12.7304L7 7.74607L11.9833 12.7304C12.2047 12.9599 12.5331 13.052 12.8419 12.9712C13.1506 12.8904 13.3915 12.6493 13.4717 12.3409C13.5519 12.0324 13.4588 11.7048 13.2284 11.4843L8.24508 6.5Z"
                fill="#0A467E"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.24508 6.5L13.2284 1.51571C13.4588 1.29521 13.5519 0.967575 13.4717 0.659124C13.3915 0.350673 13.1506 0.109578 12.8419 0.0287828C12.5331 -0.0520119 12.2047 0.0401128 11.9833 0.269642L7 5.25393L2.01668 0.269642C1.67012 -0.0619967 1.1215 -0.0558617 0.782462 0.283444C0.443426 0.622749 0.4386 1.17051 0.771603 1.51571L5.75392 6.5L0.771603 11.4843C0.541174 11.7048 0.448112 12.0324 0.528295 12.3409C0.608477 12.6493 0.849381 12.8904 1.15813 12.9712C1.46689 13.052 1.79527 12.9599 2.01668 12.7304L7 7.74607L11.9833 12.7304C12.2047 12.9599 12.5331 13.052 12.8419 12.9712C13.1506 12.8904 13.3915 12.6493 13.4717 12.3409C13.5519 12.0324 13.4588 11.7048 13.2284 11.4843L8.24508 6.5Z"
                fill="#0A467E"
              ></path>
            </svg>
          </div>
        </div>
         <MenuItem v-for="item in getCarouselList" :code="item.code" :key="item.id" :item="item" :depth="depth"/>
      </div>
    </div>
  </section>
</template>

<script>
import MenuItem from '@/components/promo/MenuItem'
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Menu",
  data: () => ({
    menu_visible: false,
    depth: 0,
  }),
  components: {
   MenuItem
  }, 
  computed: {
   ...mapGetters(['getCarouselList'])
  },
  methods: {
    ...mapActions(['fetchCarouselList'])
  },
  async mounted() {
    if(!Object.keys(this.getCarouselList).length) {
      this.fetchCarouselList()
    }
  }
};
</script>
    

<style scoped lang="scss">
    @charset "utf-8";
/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
/* END RESET */

/* BASE */
.menu-wrap {
    background-color: #F7F7F7;
    padding: 22px 15px;
    @media screen and (max-width: 960px) {
      padding-right: 0;
    }
    @media screen and (max-width: 600px) {
      padding-right: 15px;
    }
}

.main-menu {
  &__caption {
    margin-bottom: 30px;
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    color: #56565A;
      @media screen and (max-width: 960px) {
          padding-bottom: 0;
          margin-bottom: 15px;
          font-size: 24px;
          line-height: 28px;
          border-bottom: 0;
      }
  }

  &__header {
    position: sticky;
    top: 0;
    // background-color: #fff;
    z-index: 2;
  }

  &__button {
      padding: 19px 22px;
      margin-bottom: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #F7F7F7;
  }

  &__button-text {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #56565A;
  }

  &__button-arrow {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  &__close {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 25px 30px 0;
    width: calc(100% + 60px);
    margin-bottom: 15px;
    margin-left: -30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
  }

  &__close-text {
    margin-right: 8px;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #0A467E;
  }

  &__close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (max-width: 960px) {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    padding: 0 30px 25px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
    @media screen and (max-width: 560px) {
      padding: 47px 30px 25px;
    }

    @media screen and (max-width: 960px) {
      padding: 75px 30px 25px;
    }
  }

  &_visible {
    @media screen and (max-width: 960px) {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }

    @media screen and ( max-width: 600px ) {
      overflow-x: hidden;
    }
  }
}

.main.column-view {
  .main-menu {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    padding: 0 30px 25px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
    cursor: pointer;
      &__caption {
          border-bottom: 0;
          padding-bottom: 0;
      }
      &_visible {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 300ms;

          @media screen and ( max-width: 600px ) {
            overflow-x: hidden;
          }
      }
  }
}
</style>


